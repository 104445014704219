<template>
    <div>
        <NavbarStyleThree />
        <PageTitle subTitle="Get in Touch" heding="Connecting People With Knowledge" />
        <Contact />
        <FooterStyleThree />
    </div>
</template>

<script>

import FooterStyleThree from '../Layouts/FooterStyleThree.vue'
import NavbarStyleThree from '../Layouts/NavbarStyleThree.vue'
import PageTitle from '../Common/PageTitle'
import Contact from '../Contact/Contact'


export default {
    name: 'ContactPage',
    components: {
        PageTitle,
        NavbarStyleThree,
        FooterStyleThree,
        Contact,
    }
}
</script>