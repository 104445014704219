<template>
    <div>
        <NavbarStyleThree />
        <PageTitle subTitle="About Us" heding="Your Path to Effortless Rentals" />
        <About />
        <WhyChooseUs />
        <GetStarted />
        <FooterStyleThree class="bg-f9f9f9 " />
    </div>
</template>

<script>

import NavbarStyleThree from '../Layouts/NavbarStyleThree.vue'
import PageTitle from '../Common/PageTitle'
import About from '../AboutOne/About'
import WhyChooseUs from '../AboutOne/WhyChooseUs'
import GetStarted from '../AboutOne/GetStarted'
import FooterStyleThree from '../Layouts/FooterStyleThree.vue'

export default {
    name: 'AboutOnePage',
    components: {
        NavbarStyleThree,
        PageTitle,
        About,
        WhyChooseUs,
        GetStarted,
        FooterStyleThree,
    }
}
</script>