<template>
    <div class="overview-area ptb-100 with-top-border">
        <div class="container">
            <div class="overview-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12 overview-image style-one">
                        <img src="../../assets/images/overview/overview3.png" data-aos="fade-down" data-aos-duration="1200" alt="overview-image">

                    </div>
                    <div class="col-lg-5 col-md-12 overview-content">
                        <h2>Elevating Your Property Rental Experience</h2>
                        <p>
                            At Loger, we believe that finding your perfect rental should be an exciting journey, not a daunting task. 
                            Our platform is designed with you in mind, offering a range of features that redefine the way you connect with real estate agents and secure the home you've always dreamed of.
                            Loger isn't just a tool; it's a community of property seekers and real estate agents committed to excellence. 
                            Join us in creating a rental experience that's efficient, transparent, and tailored to your unique needs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReceivePayments'
}
</script>