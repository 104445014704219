<template>
    <div class="team-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="team-content" data-aos="fade-up" data-aos-duration="1200">
                        <h2>Join the Loger Agent Community</h2>
                        <p>
                            Loger Agent isn't just about transactions; it's about building a community of real estate professionals committed to excellence. 
                            Join a network where collaboration, innovation, and success are at the forefront.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JoinAgentCommunity'
}
</script>