<template>
    <div>
        <NavbarStyleThree />
        <MainBanner />
        <ReceivePayments />
        <Features />
        <DownloadApp />
        <FooterStyleThree />
    </div>
</template>

<script>
import NavbarStyleThree from '../Layouts/NavbarStyleThree'
import MainBanner from '../App/MainBanner'
import ReceivePayments from '../App/ReceivePayments'
import Features from '../App/Features'
import DownloadApp from '../App/DownloadApp'
import FooterStyleThree from '../Layouts/FooterStyleThree'

export default {
    name: 'AppPage',
    components: {
        NavbarStyleThree,
        MainBanner,
        ReceivePayments,
        Features,
        DownloadApp,
        FooterStyleThree,
    }
}
</script>