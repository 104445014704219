<template>
    <div class="working-process-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-content">
                        <h2>Why Choose Loger Agent</h2>
                        <ul class="working-process-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <div class="number">
                                    1
                                </div>
                                <h3>Direct Connections, Greater Impact</h3>
                                <p>
                                    At Loger, you connect directly with motivated property seekers. 
                                    Say goodbye to intermediaries, and embrace a platform that values direct and meaningful connections.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="number">
                                    2
                                </div>
                                <h3>Expanded Reach, Amplified Opportunities</h3>
                                <p>
                                    Loger is not just local; it's global. Showcase your expertise in your preferred regions and explore new territories. 
                                    Loger is expanding its coverage areas, ensuring you never miss a potential client.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div class="number">
                                    3
                                </div>
                                <h3>Real-Time Notifications, Stay Ahead</h3>
                                <p>
                                    Receive instant notifications when a property seeker's preferences align with your expertise. 
                                    Stay ahead of the curve and be the first to connect with potential clients actively seeking your services.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <div class="number">
                                    4
                                </div>
                                <h3>Secure Communication, Build Trust</h3>
                                <p>
                                    Loger ensures secure communication channels between you and property seekers. 
                                    Engage in transparent and trustworthy conversations without compromising personal contact details.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
                                <div class="number">
                                    5
                                </div>
                                <h3>Innovative Tools, Simplified Workflow</h3>
                                <p>
                                    Our platform is designed with your efficiency in mind. 
                                    Benefit from innovative tools that streamline your workflow, 
                                    making the property rental process smoother for you and your clients.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-image bg2">
                        <img src="../../assets/images/working-process2.png" alt="working-process">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseLogerAgent'
}
</script>