<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image bg1">
                        <img src="../../assets/images/about/about1.jpg" alt="about">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content" data-aos="fade-up" data-aos-duration="1200">
                        <span class="sub-title">Loger User</span>
                        <h2>Empowering Dreams and Simplifying Homes</h2>
                        <p> 
                            Your Path to Effortless Rentals Begins Here
                            Welcome to Loger, where we put the power of finding your dream rental directly into your hands. Loger Users are at the heart of our community, and we're here to redefine your property rental experience.

                        </p>
                        <ul class="about-list">
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutLogerUser'
}
</script>
