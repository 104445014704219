<template>
    <div>
        <NavbarStyleThree />
        <PageTitle subTitle="Our Creative Team" heding="We are not just a team, We are a Family!" />
        <Team />
        <FooterStyleThree />
    </div>
</template>

<script>

import NavbarStyleThree from '../Layouts/NavbarStyleThree.vue'
import PageTitle from '../Common/PageTitle'
import FooterStyleThree from '../Layouts/FooterStyleThree.vue'
import Team from '../Team/Team'


export default {
    name: 'TeamPage',
    components: {
        PageTitle,
        NavbarStyleThree,
        Team,
        FooterStyleThree,
    
    }
}
</script>