<template>
    <div class="app-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-banner-content">
                        <h3 data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">LOGER: YOUR PATH TO EFFORTLESS PROPERTY RENTALS </h3>
                        <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">Discover a new era in property rental with Loger, where finding your dream home is with ease.  We're not just an app, we're a revolution in the way you connect with real estate agents and secure a home without hassle</p>
                        <div class="btn-box" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                            <router-link to="/contact" class="btn-style-one blue-dark-color">
                                Get Started Now 
                                <i class="ph-caret-right"></i>
                            </router-link>
                            <router-link to="/about-us" class="btn-style-one white-color">
                                About Us 
                                <i class="ph-caret-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-banner-image">
                        <img src="../../assets/images/banner/banner2.png" data-aos="fade-up" data-aos-duration="1200" alt="banner-image">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>