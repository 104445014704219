<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image bg1">
                        <img src="../../assets/images/about/about1.jpg" alt="about">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content" data-aos="fade-up" data-aos-duration="1200">
                        <span class="sub-title">About Loger</span>
                        <h2>Empowering Dreams and Simplifying Homes</h2>
                        <p>At Loger, we envision a world where finding the perfect home is a seamless and empowering experience. We aspire to redefine the landscape of property rental by connecting seekers with their dream homes effortlessly. 
                            Our vision is to be the catalyst that transforms the way people approach and achieve their housing aspirations
                        </p>
                        <ul class="about-list">
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>