<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="privacy-policy-content">
                <h3>Loger Privacy Policy</h3>
                <p>This site is provided by <strong>Loger</strong>. <strong>Loger</strong> collects information in several ways from different parts of this site.</p>
                <strong><h3> 1. Introduction: </h3></strong> <p>
                    Thank you for using Loger. This Privacy Policy outlines how Loger collects, uses, shares, and protects your personal information. 
                    By using Loger, you agree to the terms of this Privacy Policy.

                </p><br>
                    <strong><h3> 2. Information We Collect: </h3></strong> <p>
                        User-Provided Information: We collect information provided by users during account creation, property searches, and communication with real estate agents.

                        Automatically Collected Information: Loger may collect data automatically, including IP addresses, device information, and usage patterns
                    </p><br>
                    <strong><h3> 3. How We Use Your Information </h3></strong> <p> 
                        <ul> 
                           <li> Providing and improving our services. </li> 
                            <li> Customizing user experience. </li>
                            <li> Communicating with users. </li>
                            <li> Analyzing usage patterns for optimization </li>
                        </ul>
                        
                    </p><br>
                    <strong><h3> 4. Information Sharing </h3></strong> <p>
                       <ul>
                         <li> <b> With Real Estate Agents: </b> User information may be shared with real estate agents 
                            for the purpose of facilitating property searches and communication. </li>
                          <li> <b> Third Party Service Providers: </b> We may engage third-party service providers for analytics, marketing, or other services.
                         </li>  
                       </ul>
                    </p><br>
                    <strong><h3> 5. Security: </h3></strong> <p>
                        We employ industry-standard security measures to protect user data. However, no online platform can guarantee 100% security
                    </p> <br>
                    <strong><h3> 6. Your Choices: </h3></strong> <p>
                        Users can review, update, or delete their information by accessing their account settings.
                        Users can opt out of certain communications
                    </p> <br>
                    
                    <strong><h3> 7. Cookies and Similar Technologies:  </h3></strong> <p>
                        Loger uses cookies and similar technologies to enhance user experience and collect data for analytics.
                    </p> <br>

                    <strong><h3> 8. ⁠Children’s Privacy:  </h3></strong> <p>
                        Loger is not intended for children under the age of 13. 
                        We do not knowingly collect personal information from children.
                    </p> <br>

                    <strong><h3> 9.  ⁠Changes to this Privacy Policy: </h3></strong> <p>
                        Loger may update this Privacy Policy. Users will be notified of significant changes.
                    </p> <br>

                    <strong><h3> 10.  ⁠Contact Us: </h3></strong> <p>
                        For questions or concerns about this Privacy Policy, please contact us at hello@loger.africa.
                    </p> <br>


                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>