<template>
    <div class="working-process-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-content">
                        <h2>Why Choose Loger?</h2>
                        <ul class="working-process-list">
                            <li data-aos="fade-up" data-aos-duration="1200">
                                <div class="number">
                                    1
                                </div>
                                <h3>Effortless Property Search</h3>
                                <p>Say goodbye to exhaustive searches. With Loger, finding your perfect rental is a breeze. 
                                    Effortless navigation through a vast array of property listings ensures that your dream home is just a click away.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="number">
                                    2
                                </div>
                                <h3>Direct Connections, Personalized Service</h3>
                                <p>
                                    Connect directly with verified real estate agents in your desired regions. 
                                    Enjoy a personalized and efficient experience where your preferences are a priority.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div class="number">
                                    3
                                </div>
                                <h3>Real-Time Notifications, No Missed Opportunities</h3>
                                <p>
                                    Loger's advanced algorithms ensure you receive instant notifications when a property aligns with your criteria. 
                                    No more missed opportunities—your dream home finds you.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <div class="number">
                                    4
                                </div>
                                <h3>Secure Communication, Peace of Mind</h3>
                                <p>
                                    Communicate with agents without revealing personal contact details. 
                                    Loger provides a secure channel for transparent and safe communication throughout your rental journey.
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
                                <div class="number">
                                    5
                                </div>
                                <h3>Expanded Reach, More Choices:</h3>
                                <p>
                                    Whether you're in the heart of the city or seeking serenity in the suburbs, 
                                    Loger is expanding its coverage areas to cater to your diverse needs. 
                                    More regions mean more choices for your ideal home.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="working-process-image bg1">
                        <img src="../../assets/images/working-process.jpg" alt="working-process">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>