<template>
    <div class="features-area bg-f3feff pt-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title light-green-color">Key Features</span>
                <h2>Discover the Loger Advantage</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-home"></i>
                        </div>
                        <h3>Effortless Property Search</h3>
                        <p>Navigate through a vast array of property listings effortlessly. 
                            Loger simplifies the search process, ensuring that you find the perfect match for your lifestyle and preferences
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-features-box active">
                        <div class="icon">
                            <i class="flaticon-people-1"></i>
                        </div>
                        <h3>Direct Access to Agents</h3>
                        <p>Say goodbye to middlemen. Loger connects you directly with verified real estate agents in your desired regions, 
                            allowing for a more personalized and efficient experience</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-bell"></i>
                        </div>
                        <h3>Real-Time Notifications</h3>
                        <p>Time is of the essence. With Loger's advanced algorithms, you receive instant notifications when a property that aligns with your criteria becomes available. 
                            No more missed opportunities.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-updated"></i>
                        </div>
                        <h3>Secure and Transparent Communication</h3>
                        <p>Your safety and privacy matter. Loger provides a secure communication channel that allows you to interact with agents without divulging personal contact details, 
                            ensuring a safe and transparent rental process.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-brain"></i>
                        </div>
                        <h3>Expanded Coverage Areas</h3>
                        <p>Whether you're in the hustle and bustle of the city or seeking tranquility in the suburbs, 
                            Loger is expanding its coverage areas to cater to your diverse needs.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-light-bulb"></i>
                        </div>
                        <h3>Innovation at Your Fingertips</h3>
                        <p>Loger is more than just a platform; it's a commitment to innovation. 
                            We're rolling out a suite of features that not only simplify your property search but also make the entire 
                            communication process with agents a breeze.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>