<template>
    <div>
        <NavbarStyleThree />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <h1>Privacy Policy</h1>
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
        <PrivacyPolicy />
        <FooterStyleThree />
    </div>
</template>

<script>

import NavbarStyleThree from '../Layouts/NavbarStyleThree.vue'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import FooterStyleThree from '../Layouts/FooterStyleThree.vue'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        NavbarStyleThree,
        PrivacyPolicy,
        FooterStyleThree,
    }
}
</script>