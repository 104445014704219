<template>
    <div class="team-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="team-content" data-aos="fade-up" data-aos-duration="1200">
                        <h2>Join the Loger Community</h2>
                        <p>
                            Loger Users aren't just part of a platform; they're part of a community committed to a seamless rental experience. 
                            Join us as we redefine the way you find and secure your ideal rental.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JoinCommunity'
}
</script>