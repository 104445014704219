<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image bg1">
                        <img src="../../assets/images/about/about1.jpg" alt="about">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content" data-aos="fade-up" data-aos-duration="1200">
                        <span class="sub-title">Loger Agent</span>
                        <h2>Elevate Your Real Estate Journey</h2>
                        <p> 
                            Welcome to Loger Agent, where we empower real estate professionals to thrive in the dynamic world of property rentals. 
                            Our platform is more than a tool; it's your gateway to a new era of efficiency, transparency, and unparalleled success in the real estate market.
                        </p>
                        <ul class="about-list">
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutLogerAgent'
}
</script>
