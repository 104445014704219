<template>
    <div class="get-started-area ptb-100">
        <div class="container">
            <div class="get-started-inner-area" data-aos="fade-up" data-aos-duration="1200">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <h2>
                            <em>Ready to Elevate Your Real Estate Business? </em><br/> <br />
                            Experience the future of real estate with Loger Agent. 
                            Be part of a revolution that transforms how you connect, engage, and succeed in the dynamic world of property rentals.
                        </h2>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GettingStartedAgent'
}
</script>