<template>
    <div>
        <NavbarStyleThree />
        <PageTitle subTitle="" heding="Loger Agents" />
        <AboutLogerAgent />
        <WhyChooseLogerAgent />
        <JoinAgentCommunity />
        <GettingStartedAgent />
        <FooterStyleThree class="bg-f9f9f9 " />
    </div>
</template>

<script> 

import NavbarStyleThree from '../Layouts/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import AboutLogerAgent from '../AboutLogerAgent/AboutLogerAgent'
import WhyChooseLogerAgent from '../AboutLogerAgent/WhyChooseLogerAgent'
import FooterStyleThree from '../Layouts/FooterStyleThree'
import GettingStartedAgent from '../AboutLogerAgent/GettingStartedAgent'
import JoinAgentCommunity from '../AboutLogerAgent/JoinAgentCommunity'


export default {
    name: 'AboutOnePage',
    components: {
    PageTitle,
    AboutLogerAgent,
    WhyChooseLogerAgent,
    GettingStartedAgent,
    JoinAgentCommunity,
    FooterStyleThree,
    NavbarStyleThree
}
}
</script>