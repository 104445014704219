<template>
    <div class="get-started-area ptb-100">
        <div class="container">
            <div class="get-started-inner-area" data-aos="fade-up" data-aos-duration="1200">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <h2>
                            Join Loger today and redefine how you approach property rental. 
                            Efficiency, transparency, and satisfaction are just a click away. 
                            Welcome to a smarter way to find your ideal home with Loger
                        </h2>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetStarted'
}
</script>