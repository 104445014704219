<template>
    <div>
        <NavbarStyleThree />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <h1>Terms & Conditions</h1>
                    <ul>
                        <li><router-link to="/">Home</router-link></li>
                        <li>Terms & Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
        <TermsConditions />
        <FooterStyleThree />
    </div>
</template>

<script>
import NavbarStyleThree from '../Layouts/NavbarStyleThree.vue'
import TermsConditions from '../TermsConditions/TermsConditions'
import FooterStyleThree from '../Layouts/FooterStyleThree'

export default {
    name: 'TermsConditionsPage',
    components: {
    
        NavbarStyleThree,
        TermsConditions,
        FooterStyleThree,
    }
}
</script>