<template>
    <div class="get-started-area ptb-100">
        <div class="container">
            <div class="get-started-inner-area" data-aos="fade-up" data-aos-duration="1200">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <h2>
                            <em> Ready to Redefine Your Rental Journey? </em><br/><br/>
                            Experience the future of renting with Loger. 
                            Join a community where efficiency, transparency, and satisfaction are at the forefront of your property search.
                        </h2>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetStartedLu'
}
</script>