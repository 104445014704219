<template>
    <div class="terms-conditions-area ptb-100">
        <div class="container">
            <div class="terms-conditions-content">
                <h3>Loger Terms and Conditions</h3>
                <strong><h3> 1. Acceptance of Terms: </h3></strong> <p>
                    By accessing and using the Loger platform, you agree to be bound by these Terms and Conditions
                </p><br>
                    <strong><h3> 2. User Responsibilities: </h3></strong> <p>
                        Users are responsible for the accuracy of the information provided. Users must adhere to all applicable laws and regulations
                    </p><br>
                    <strong><h3> 3. ⁠Privacy and Security: </h3></strong> <p> 
                        Loger takes user privacy seriously. User data will be handled in accordance with our Privacy Policy. 
                        Users are responsible for maintaining the confidentiality of their account credentials.
                    </p><br>
                    <strong><h3> 4.  ⁠Property Listings: </h3></strong> <p>
                        Real estate agents are responsible for the accuracy of property listings. 
                        Loger reserves the right to remove or modify any listing that violates the terms or is deemed inappropriate
                    </p><br>
                    <strong><h3> 5. Communication: </h3></strong> <p>
                        Loger provides secure communication channels between users and agents. 
                        Users and agents agree to communicate respectfully and professionally.
                    </p> <br>
                    <strong><h3> 6. Intellectual Property:  </h3></strong> <p>
                        Loger retains all intellectual property rights associated with the platform. 
                        Users and agents must not use Loger's branding, logos, or content without explicit permission.
                    </p> <br>
                    
                    <strong><h3> 7. User Conduct: </h3></strong> <p>
                        Users and agents must not engage in any activity that violates laws or infringes on the rights of others. 
                        Harassment, spam, and fraudulent activities are strictly prohibited
                    </p> <br>

                    <strong><h3> 8. Termination: </h3></strong> <p>
                        Loger reserves the right to suspend or terminate accounts for violations of these terms. 
                        Users can terminate their accounts at any time.
                    </p> <br>

                    <strong><h3> 9. Liability: </h3></strong> <p>
                        Loger is not responsible for the conduct of users or agents. Users use the platform at their own risk.
                    </p> <br>

                    <strong><h3> 10. Changes to Terms: </h3></strong> <p>
                        Loger reserves the right to modify these Terms and Conditions. 
                        Users will be notified of significant changes.
                    </p> <br>

                    <strong><h3> 11. Governing Law: </h3></strong> <p>
                        These terms are governed by the laws of the Federal Republic of Nigeria.
                    </p> <br>

                    <strong><h3> 12. Contact Information: </h3></strong> <p>
                        For any questions or concerns, contact Loger at hello@loger.africa. 
                    </p> <br>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsConditions'
}
</script>