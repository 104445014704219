<template>
    <div>
        <NavbarStyleThree />
        <PageTitle subTitle="" heding="Loger Users" />
        <AboutLogerUser />
        <WhyChooseUs />
        <JoinCommunity />
        <GettingStartedLu />
        <FooterStyleThree class="bg-f9f9f9 " />
    </div>
</template>

<script> 

import NavbarStyleThree from '../Layouts/NavbarStyleThree.vue'
import PageTitle from '../Common/PageTitle'
import AboutLogerUser from '../AboutLogerUser/AboutLogerUser'
import WhyChooseUs from '../AboutLogerUser/WhyChooseUs'
import FooterStyleThree from '../Layouts/FooterStyleThree.vue'
import GettingStartedLu from '../AboutLogerUser/GettingStartedLu'
import JoinCommunity from '../AboutLogerUser/JoinCommunity'


export default {
    name: 'AboutOnePage',
    components: {
    PageTitle,
    AboutLogerUser,
    WhyChooseUs,
    GettingStartedLu,
    JoinCommunity,
    FooterStyleThree,
    NavbarStyleThree
}
}
</script>