<template>
    <div>
        <NavbarStyleThree />
        <div class="page-title-area">
            <div class="container">
                <div class="page-title-content">
                    <span class="sub-title">Frequently Asked Questions</span>
                    <h1>Ciao! How Can We Help You?</h1>
                </div>
            </div>
        </div>
        <Faq />
        <FooterStyleThree />
    </div>
</template>

<script>

import NavbarStyleThree from '../Layouts/NavbarStyleThree'
import Faq from '../Faq/Faq'
import FooterStyleThree from '../Layouts/FooterStyleThree'

export default {
    name: 'FaqPage',
    components: {
    NavbarStyleThree,
    FooterStyleThree,
    Faq,
}
}
</script>