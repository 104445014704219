<template>
    <div class="faq-area bg-f1f5fd">
        <div class="container">
            <div class="faq-accordion-content">
                <div class="box">
                    <h3>Frequently Asked Questions (FAQ)</h3>
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is Loger?
                            </button>
                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p><strong>Loger</strong> is a revolutionary platform that connects property seekers directly with verified real estate agents. It simplifies the property rental process by facilitating direct communication 
                                        and providing a seamless experience for both users and agents.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How does Loger work for Property Seekers?
                            </button>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p> 
                                        Property seekers can create an account, set their preferences, and instantly connect with real estate agents covering their desired regions. Loger's real-time matching system ensures that users receive instant notifications 
                                        when a property that fits their criteria becomes available.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                How do I become a Loger Agent?
                            </button>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>
                                        Real estate agents can register on Loger by providing necessary details and verifying their credentials. 
                                        Once approved, agents can define their coverage areas and start receiving property requests from users in those regions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                               How secure is communication on Loger?
                            </button>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>
                                        Loger prioritizes user safety. Secure communication channels are provided to allow users and 
                                        agents to interact without sharing personal contact details directly.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Can I list my property on Loger?
                            </button>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>
                                        No! Loger primarily facilitates communication between property seekers and real estate agents.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                How do I edit my account Information?
                            </button>
                            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>
                                        Users can edit their account information by accessing their account settings. 
                                        For further assistance, contact our support team at hello@loger.africa.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                What regions does Loger cover?
                            </button>
                            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    <p>
                                        We aspire to cover everywhere in Nigeria soon but We are starting with Lagos and we have corvered everywhere.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Faq'
}
</script>