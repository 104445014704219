<template>
    <div class="overview-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="overview-box">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 overview-image">
                        <img src="../../assets/images/overview/overview1.png" data-aos="fade-up" alt="overview">
                    </div>
                    <div class="col-lg-6 col-md-12 overview-content">
                        <span class="sub-title">Why Choose Us?</span>
                        <h2>Revolutionizing Property Rental</h2>
                        <p>
                            Our mission at Loger is to create a revolutionary platform that transforms the property rental journey. We are committed to connecting property seekers directly with verified real estate agents in their desired regions, ensuring a transparent, secure, and efficient process. 
                            Through innovation and a dedication to user satisfaction, we aim to be the go-to destination for individuals seeking their ideal rental homes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>