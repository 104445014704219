<template>
    <div class="team-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="team-content" data-aos="fade-up" data-aos-duration="1200">
                        <span class="sub-title">Team</span>
                        <h2>Our Creative Team Members</h2>
                        <p>
                            At Loger, our success is driven by a team of dedicated professionals who share a common goal: to revolutionize the property rental experience. 
                            Our diverse and dynamic team brings a wealth of expertise, creativity, and a shared passion for creating a seamless platform that connects 
                            property seekers with their dream homes
                        </p>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="team-member-list">
                        <ul>
                            <li data-aos="zoom-in" data-aos-duration="1200">
                                <img src="../../assets/images/team/team1.png" alt="member-image">
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1200" data-aos-delay="100">
                                <img src="../../assets/images/team/team2.png" alt="member-image">
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1200" data-aos-delay="200">
                                <img src="../../assets/images/team/team3.png" alt="member-image">
                            </li>
                            <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                                <img src="../../assets/images/team/team4.png" alt="member-image">
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1200" data-aos-delay="500">
                                <img src="../../assets/images/team/team7.png" alt="member-image">
                            </li>
                            <li data-aos="fade-down" data-aos-duration="1200" data-aos-delay="500">
                                <img src="../../assets/images/team/team8.png" alt="member-image">
                            </li>
                        </ul>
                        <img src="../../assets/images/shape/bg-shape1.jpg" alt="bg-image" class="bg-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="team-area pb-75">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-team-member bg1">
                        <img src="../../assets/images/team/team7.jpg" alt="member-image">
                        <div class="content">
                            <h3>Tolulope Farotimi</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.linkedin.com/in/tolufarotimi/7" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-team-member bg2">
                        <img src="../../assets/images/team/team8.jpg" alt="member-image">
                        <div class="content">
                            <h3>Ayomitide OAJ</h3>
                            <ul class="social">
                                
                                <li>
                                    <a href="https://www.linkedin.com/in/ayomitide-oaj-ba542b167/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-team-member bg3">
                        <img src="../../assets/images/team/team9.jpg" alt="member-image">
                        <div class="content">
                            <h3>Samuel Adetayo-Adedokun</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.linkedin.com/in/samuel-adetayo-adedokun-8a34a1143/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-team-member bg4">
                        <img src="../../assets/images/team/team10.jpg" alt="member-image">
                        <div class="content">
                            <h3>Beauty Onolunose</h3>
                            <ul class="social">
                               
                                <li>
                                    <a href="https://www.linkedin.com/in/beauty-onolunose/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                    <div class="single-team-member bg8">
                        <img src="../../assets/images/team/team14.jpeg" alt="member-image">
                        <div class="content">
                            <h3>Ogechukwukanma Chizea</h3>
                            <ul class="social">
                               
                                <li>
                                    <a href="https://www.linkedin.com/in/ogechukwukanma-chizea-42498461/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-team-member bg6">
                        <img src="../../assets/images/team/team12.jpg" alt="member-image">
                        <div class="content">
                            <h3>Joyce Dinma</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.linkedin.com/in/joyce-dinma-222460269/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1200">
                    <div class="single-team-member bg7">
                        <img src="../../assets/images/team/team13.jpg" alt="member-image">
                        <div class="content">
                            <h3>Titilayo Oluwatobi</h3>
                            <ul class="social">
                                <li>
                                    <a href="https://www.linkedin.com/in/titilayo-oluwatobi/" target="_blank">
                                        <i class="flaticon-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Team'
}
</script>